import React from 'react';
// import { Router } from '@reach/router';
import Layout from '../../../components/layout';

import UserRegister from './../../../components/s4n/AccountManagement/Register';

const User = () => {
  return (
    <Layout>
       
        <UserRegister />

    </Layout>
  )
}

export default User;